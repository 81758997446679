import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={"Zurück"} center>
      <SEO title="Kontakt" />
      <h1>Kontakt</h1>
      <p>
        Anfragen nehmen wir gerne per E-Mail entgegen:
        <br />
        <a href="mailto:mail@aarefabrik.ch">mail@aarefabrik.ch</a>
      </p>

      <h1>Impressum</h1>
      <p>
        Aarefabrik GmbH
        <br /> Könizstrasse 161 <br />
        3097 Liebefeld-Bern
        <br />
        <br />
        CHE-363.439.403
      </p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
